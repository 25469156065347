import React, { Component } from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { grey, purple } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { ResponsiveContainer} from 'recharts';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L, { LatLngBoundsExpression } from 'leaflet';
//import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import PageBase from '../site/PageBase';
//import LiveMap from './LiveMap';
import {apipost, bizpost} from '../../ajax';
import { LatLngExpression } from 'leaflet';


const styles = (theme:Theme):any => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
  },
  // navigation: {
  //   fontSize: 15,
  //   fontWeight: 300,
  //   color: grey['600'],
  //   paddingBottom: 15,
  //   display: 'block'
  // },
  // title: {
  //   fontSize: 24,
  //   fontWeight: 300,
  //   marginBottom: 20,
  //   color:'white'
  // },
  // paper: {
  //   padding: 5,
  // },
  // clear: {
  //   clear: 'both'
  // },
  boardpaper: {
    backgroundColor: 'white',
    //maxHeight: 600,
    height: '100%',
    // height: 150
    marginLeft: 10,
    marginRight: 10,
  },
  div: {
    // height: 95,
    //padding: '5px 15px 0 15px'
  },
  header: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: purple['600'],
    padding: 10,
  }
});

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

class Mapboard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      //dd: this.props.userData.dd,
      ping: null,
      timer: 60000, //300000,
      count:0,
      lastread: null,
      recordset: [],
      previousset:[],
      currentset:[]
    };
  }

  // componentDidMount(){
  //   const {timer} = this.state;
  //   var ping = setInterval(async()=>{
  //     try{
  //       const baseparams = {s:'security',a:'dashdata'};
  //       const response = await bizpost(baseparams);
  //       //console.log('dd resp',response);
  //       this.setState({dd:response.dd});
  //     }
  //     catch(error){
  //       console.log('dd error',error);
  //     } 
  //   },timer);
  //   this.setState({ping:ping});
  // }

  // componentWillUnmount(){
  //   if(this.state.ping) clearInterval(this.state.ping);
  // }

  mapload = async(filename:string) => {
    try{
      const baseparams = {filename}; //,sta:date
      const response = await apipost('/api/resource/map','',baseparams);
      //this.setState({markers:response.sd});
      //this.map.addLayer(this.liveLayer);
      //this.liveLayer.clearLayers();
      //this.loadMakersLeaflet();
      return response; //TODO: return file
    }
    catch(error){
      console.log('map.bizpost error',error);
      return Promise.reject(error);
    } 
  //TODO: call LoadMarkers
  
}

  baseload = async() => {
    try{
      return await this.mapload('ghana-regions.json');
    }
    catch(error){
      console.log('baseload error',error);
      return Promise.reject(error);
    } 
  }

  placesload = async() => {
    try{
      return await this.mapload('ghana-towns.json');
    }
    catch(error){
      console.log('placesload error',error);
      return Promise.reject(error);
    } 
  }

  merge = (p:string, ...arrs:any[]) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);


  liveLoad = async(code:any,date:any) => {
      try{
        const {recordset:previousset,lastread} = this.state;
        const {userData} = this.props || {};
        const {eni} = userData;
        //const start = moment.format()
        //TODO: I am hardcoding code and start until baseparams is properly fixed. start is a timestamp so it will 
        // not accept empty string. either null or a valid timestamp
        const baseparams = {s:'marker',a:'find',eni,stp:lastread}; //,sta:date, ,cod:'1M8wJEWIAcIEfILnCHpISD'
        const response = await bizpost(baseparams);
        const {sd:currentset} = response;
        const recordset = this.merge('rid',previousset,currentset);
        this.setState({recordset,previousset,currentset});
        //this.map.addLayer(this.liveLayer);
        //this.liveLayer.clearLayers();

        //this.loadMakersLeaflet();
      }
      catch(error){
        console.log('map.bizpost error',error);
      } 
    //TODO: call LoadMarkers
    
  }

  ttFormatTime = (stamp:any) => {
    return moment.unix(stamp).format('DD MMM');
  }

  render() {
    const {title,userData,classes,theme} = this.props;
    const {recordset} = this.state;
    //const {recordset,previousset,currentset} = this.state;
    const {bd} = userData;
    const mapcenter:LatLngExpression = [8.977850, 39.253099]; //[7.5823, -1.9470];
    const apexpos:LatLngExpression = [9.01055, 38.74750]; //[6.647991, -0.579113];
    const apextext = `CBE. <br /> Event Location.`;
    //const bounds = new  L.LatLngBounds([[4.5392525, -3.260786],[11.1748562,	1.2732942]]);
    //const bounds:LatLngBoundsExpression = [[4.5392525, -3.260786],[11.1748562,	1.2732942]];
    //-3.24437008301, 4.71046214438, 1.0601216976, 11.0983409693
    const bounds:LatLngBoundsExpression = [[4.71046214438, -3.24437008301],[11.0983409693, 1.0601216976]];
    return (
      <PageBase navigation="MPSMS / Map board" subid={(bd||{}).eni}>
      <div className={classes.root}>
        <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12}>
            {/* <LiveMap /> */}
            <Paper className={classes.boardpaper}>
              <div className={classes.header}>{title}</div>
              <div className={classes.div}>
                <ResponsiveContainer >
                  <MapContainer center={mapcenter} zoom={7} scrollWheelZoom={false} style={{height:'80vh',width:'100%'}} bounds={bounds}>
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" bounds={bounds}
                    />
                    <Marker position={apexpos}>
                      <Popup>
                        {apextext}
                      </Popup>
                    </Marker>
                    {recordset.map((record:any, idx:number) => 
                      <Marker key={record.rid} position={record.pos}>
                      <Popup>
                        <span>{record.txt}</span>
                      </Popup>
                    </Marker>
                    )}
                  </MapContainer>
                </ResponsiveContainer>
              </div>
            </Paper>
            
          </Grid>
        </Grid>
      </div>
      </PageBase>
    );
  }
};

export default withStyles(styles, { withTheme: true })(Mapboard);
