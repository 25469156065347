import React, { Component } from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {bizpost} from '../../ajax';
import {extendedstyles} from '../../app/appstyles';
import ClosableBar from './ClosableBar';
import { TextField } from '@mui/material';
import Moment from 'moment';

const extrastyles = (theme:Theme):any => ({
  submitBtn: {
    marginLeft: 10
  },
  dialogcontent:{
    margin: theme.spacing(3)
  },
  textfield: {
    margin: theme.spacing(1)
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const styles = extendedstyles(extrastyles);

class DateDialog extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      open: null,
      startDate:Moment(new Date()).format('YYYY-MM-DD'),
      endDate:Moment(new Date()).format('YYYY-MM-DD')
    };
  }

  onOpen = () => {
    this.setState({open: true});
  };

  onClose = () => {
    this.setState({open: false});
  };

  onStartChange = (event:any) => {
    const sdate = event.target.value;
    const nsd = new Date(sdate);
    let edate = new Date(nsd);
    edate.setDate(nsd.getDate() + 1);
    const endDate = Moment(edate).format('YYYY-MM-DD');
    this.setState({startDate: sdate});
    this.setState({endDate: endDate});
  } 

  onEndChange = (event:any) => {
    const edate = event.target.value;
    if( edate > this.state.startDate ) this.setState({endDate: edate});
  } 

  onFilter = () => {
    const data = {
      start: this.state.startDate,
      end: this.state.endDate
    }
    const {onFilter:filterfn,onClose} = this.props;
    if(typeof filterfn === 'function') filterfn(data);
    onClose();
  }

  render() {
    const {open,onClose,classes} = this.props;
    const title = "Select Date";

    return (
      // <div>
      <Dialog open={open}  className={classes.dialog} classes={{paper: classes.dialogcontent}} maxWidth={'md'}>
          <DialogTitle className={classes.titlebar}>
            <ClosableBar onClose={onClose}>{title}</ClosableBar>
          </DialogTitle>
          <DialogContent className={classes.dialogcontent}>
              <TextField 
                className={classes.textfield}
                size='small' 
                variant='outlined' 
                type='date' 
                label='Start Date' 
                value={this.state.startDate}
                onChange={this.onStartChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField 
                className={classes.textfield}
                size='small' 
                variant='outlined' 
                type='date' 
                label='End Date' 
                value={this.state.endDate}
                onChange={this.onEndChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
          </DialogContent>
          <DialogActions className={classes.actionbox}>
            <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={this.onFilter}>Filter</Button>
          </DialogActions>
        </Dialog>
      // </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DateDialog);
