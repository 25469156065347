import React, {Component} from 'react';

import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar'; 
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Help from '@mui/icons-material/Help';
import CenterGrid from '../../components/layout/CenterGrid';
import {apipost} from '../../ajax';
import APIPing from '../custom/APIPing';
import APICheck from '../custom/APICheck';
import APIConnect from '../custom/APIConnect';
import APIDisconnect from '../custom/APIDisconnect';
import APIBalance from '../custom/APIBalance';
import APIStatement from '../custom/APIStatement';
import APIDeposit from '../custom/APIDeposit';
import APITopup from '../custom/APITopup';
import APIService from '../custom/APIService';
import APICallback from '../custom/APIDeposit';
import { extendedstyles } from '../appstyles';

const extrastyles = (theme:Theme) => ({
  loginbox: {
    //width: 450
    margin:'50px auto',
    display: 'inline-block'
  },
  loginform: {
    padding: theme.spacing(1),
  },
  titlebar: {
    color: 'white',
    textALign: 'center',
    flex:1
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  menu: {
    //width: 200,
  },
  hide: {
    display:'node'
  },
  show: {
    display:'inline-block'
  },
  logo: {
    cursor: 'pointer',
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: 'url(lib/img/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
    height: 20,
    width:25,
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  },

  progress: {
    margin: theme.spacing(0, 2),
  },
  spinner: {
    backgroundImage: 'url(lib/img/loading.gif)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 80,
    height: 80,
  },
  note:{
    margin: theme.spacing(1),
  },
  error: {
    color: '#ff0000',
  }
  
});

const styles = extendedstyles(extrastyles);

class Testing extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      sbopen: false,
      sbmesg: '',

      username: '',
      password:'',
      showPassword: false,
      //context: props.context,
      serviceid: 1,
      remember: false,

      valid: props.valid||true,
      balanceValid: false,
      balanceResponse: 'N/A',
      statementValid: false,
      statementResponse: 'N/A',
      data: {},

      percent: 0,
      fwopen: false,
      error: false,
      status: 'Saving',
    };
  }

  setValid = (valid:any) => {
    this.setState({valid})
  }

  setBalanceValid = (balanceValid:any) => {
    this.setState({balanceValid})
  }

  setStatementValid = (statementValid:any) => {
    this.setState({statementValid})
  }

  setParam = (key:string,val:any,done:any) =>{
    try{
      this.setState({[key]:val});
    }
    catch(e){
      //this.props.deb.log('setParam error: ',key,val);
      console.log('setParam error: ',key,val);
    }
  }

  setParamItem = (key:string,name:any,val:any,done:any) =>{
    try{
      this.setState((prevState:any)=>({[key]:{...prevState[key],[name]:val}}),done)
    }
    catch(e){
      //this.props.deb.log('setParamItem error: ',key,name,val);
      console.log('setParamItem error: ',key,name,val);
    }
  }

  handleProgress = (ev:any,perc:any) => {
    this.setState({percent:perc});
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  //this method is designed for basecard.handleSend
  doSubmit = async() => {
    const { classes, theme, showNotification, ...extraProps } = this.props;  //fmtUI, logo, title, switchFn,
    //const { record,data,extras,setItem } = this.props;
    const { record} = this.props;
    //const {data:formdata} = data;

    //const {code,service,mode,sconfig,onResponse,ft,ms,mt} = this.props;
    try{
      let url = '/api/balance';
      const {data} = this.state;
      console.log('data',data);
      const {tkn:token,cod:code,acn:accountNumber} = data.data;
      let params = {code,accountNumber};
      ////let recs = (mode==='delete')?{rid:+(record.rid),stp:record.stp}:record;
      //let params = Object.assign(record,formdata,{code,accountNumber});
      //console.log('service request ',params);
      
      this.startLoading();
      const response = await apipost(url,token,params);
      
      if (response.success){
        showNotification(response.sm,'success',response.st)
        //onResponse(true,response);
        return response;
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          showNotification(response.message,response.type||'error',response.title);
          return Promise.reject(response);
        }
      }      
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      showNotification(error.message||"request failed",error.type||'warn',error.title||'Update Error');
      return Promise.reject(error);
    }
    finally{
      this.stopLoading();
    }
     
  }

  render() {
    const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    const {data,record,balanceValid,statementValid,valid} = this.state;
    const {balanceResponse,statementResponse} = this.state;
    const title = "Service Middleware Testing";
    //const vertical = 'top', horizontal = 'center';
    const logosrc = '/api/logo/icon.png';

    return (
      <CenterGrid xs={12} sm={6}>
        <Paper elevation={3} square={false}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.logo} style={{backgroundImage: `url(${logosrc})`}}/>
            <Typography variant="h4" className={classes.titlebar}>
              Test API...
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
        <APIPing 
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APICheck 
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APIConnect 
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APIDisconnect 
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APIBalance 
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APIStatement 
            data={data}
            record={{}}
            valid={statementValid}
            setValid={this.setStatementValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={statementResponse}
          />   
          <APIDeposit
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APITopup
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APIService 
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          <APICallback 
            data={data}
            record={{}}
            valid={balanceValid}
            setValid={this.setBalanceValid.bind(this)}
            setParam={this.setParam.bind(this)}
            setItem={this.setParamItem.bind(this)}
            doSubmit={this.doSubmit.bind(this)}
            response={balanceResponse}
          />
          
        </div>
          
        </Paper>
      </CenterGrid>
    );
  }

}

export default withStyles(styles, { withTheme: true })(Testing);
