import React, { Component, Fragment } from 'react';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import {blue,pink,green} from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
//import ResourceView from '../view/ResourceView';
import { PDFIcon, CSVIcon, JSONIcon, ZIPIcon} from '../../components/icons';
import Download from '../../ajax/download';
import {extendedstyles} from '../appstyles';
import {apipost} from '../../ajax';
import {getNames} from '../views/txnconfig';

import {getConfig} from '../../core';

import { clone } from '../../core/utils';
import { formatDate } from '../../components/renderers';
import { create, isValid } from '../../components/form';


const extrastyles = (theme:Theme) => ({
  root: {
    //width: '100%',
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  // secondaryHeading: {
  //   fontSize: theme.typography.pxToRem(15),
  //   color: theme.palette.text.secondary,
  // },
  // secondaryHeading2: {
  //   fontSize: theme.typography.pxToRem(15),
  //   color: blue[600], //theme.palette.text.secondary,
  // },
  topheading: {
    fontWeight:'bold',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '50%',
  },
  // gridtitle: {
  //   fontSize: theme.typography.pxToRem(15),
  //   color: pink['500'],
  //   fontWeight:'bold',
  // },
  // gridroot: {
  //   width: '100%',
  // },
  // gridrow: {
  //   display: 'flex',
  //   width: 600,
  //   border: '1px solid #eeeeee',
  //   textAlign: 'left',
  // },
  // gridcolumnauto: {
  //   flexBasis: 'fill',
  //   textAlign: 'left',
  //   borderRight: '1px solid #eeeeee',
  // },
  // gridcolumn30: {
  //   flexBasis: 'auto',
  //   width: '200px',
  //   textAlign: 'left',
  //   borderRight: '1px solid #eeeeee',
  // },
  // gridcontent: {
  //   textAlign: 'left',
  //   paddingLeft: '20px',
  // },
  // dcolumn: {
  //   width: '100%',
  //   display: 'flex',
  //   flexBasis: '50%',
  // },
  helper: {
    borderLeft: `2px solid ${theme.palette.text.hint}`,
    padding: theme.spacing(1, 2),
  },
  // link: {
  //   color: theme.palette.primary[500],
  //   textDecoration: 'none',
  //   '&:hover': {
  //     textDecoration: 'underline',
  //   },
  // },
  iconsroot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  customicon: {
    margin: theme.spacing(2),
    color: pink["500"]
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: green[200],
    },
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
});

const styles = extendedstyles(extrastyles);

class APIBalance extends Component<any,any> {
  constructor(props:any) {
    super(props);
    this.state = {
      loaded:false,
      views:[],
      count:0,
      data: [],


      source: {},
      preview: false,
      valid: false,
    };
  }

  componentDidMount(){
    this.props.setValid(this.state.valid);
  }

  formconfig = () => {
    //combo.inputLabelProps => cilp
    const cbip = {InputLabelProps:{ shrink:true,}};
    const config = {
      service: {
        name: 'balanceform',
        title: 'Balance Form',
        eform: [
          { id:'tkn',label:'Token',type:'pf',req:1,ops:{ helperText:'Enter API Token',pattern:"[a-zA-Z]+", value:'DE9AE84D-950A-051A-E8C7-44801ED21777' } },
          { id:'cod',label:'Code',type:'tf',req:1,ops:{ helperText:'Enter Partner Code',pattern:"[0-9]+" } },
          { id:'acn',label:'Account Number',type:'tf',req:1,ops:{ helperText:'Enter Account Number',pattern:"[0-9]{13}" } },
          { id:'tri',label:'Track Id',type:'tf',req:1,ops:{ helperText:'TrackID',pattern:"[a-zA-Z]+", value:'DE9AE84D-950A-051A-E8C7-44801ED21777' } },
        ],
      },
      create: true,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  }

  setValid = (data:any) => {
    const sconfig = this.formconfig();
    //const {mode} = this.props;
    const mode = 'add';
    const valid = isValid(sconfig,data,mode);
    this.props.setValid(valid);
  }

  onChange = (name:any) => (value:any) => {
    const {data} = this.props;
    const newdata = {...data.data,[name]:value};
    this.setValid(newdata);
    this.props.setItem('data','data',newdata);
  }

  makeform = () => {
    const config = this.formconfig();
  
    const { record, extras, classes } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    const form = create(mode,sdata,record,formatters,onChange,classes, extras);
    return form;
  }

  // render() {
  //   //const {data,classes} = this.props;
  //   const form = this.makeform();
  //   return form;
  // }

  onClick = (name:any) => () => {
    //var btn = event.target;
    var {data,record} = this.props;
    var d = new Download();
    d.downloadFile(name,data,record);
  } 

  onClose = (event:any) => {
    const {onClose} = this.props;
    this.setState({loaded:false});
    if(typeof onClose == 'function') onClose();
  }

  onReset = (event:any) => {
    //reset for fields and data
  }

  render() {
    const {valid,doSubmit,classes } = this.props;
    //const {data} = this.state;
    const title = 'Account Balance';
    const form = this.makeform();
    return (
      <Fragment>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classnames(classes.heading,classes.topheading)}>
                {title}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
          <Paper>
            <div>
              {form}
            </div>
            <div className={classes.actionbox}>
            <Button variant="contained" color="secondary" className={classes.action} disabled={!valid} onClick={this.onReset}>Cancel</Button>
            <Button variant="contained" color="primary" className={classes.action} disabled={!valid} onClick={doSubmit}>Submit</Button>
            </div>
            </Paper>
          </AccordionDetails>
        </Accordion>
      </Fragment>  
    );
  }
}

export default withStyles(styles)(APIBalance);
