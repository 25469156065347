import React, { Component } from "react";
import PropTypes from "prop-types";
import { Theme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
//import ThemeProvider from '@mui/material/styles/ThemeProvider';
//import withWidth, {LARGE, SMALL} from '@mui/material/utils/withWidth';
import Snackbar from "@mui/material/Snackbar";
//import ThemeDefault from '../components/themes/theme-default';
import Header from "../site/Header";
import LeftDrawer from "../site/LeftDrawer";
import Content from "../site/Content";
import LogoutWizard from "../dialogs/LogoutWizard";
//import Data from '../base/data';
import { Data } from "../base";

const styles = (theme: Theme): any => ({
  root: {
    width: "100%",
    height: "99vh", //430,
    marginTop: 0, //theme.spacing(3),
    zIndex: 1,
    //overflow: 'hidden',
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  content: {
    overflow: "scroll",
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
});
////////////////////////////////

class HomePage extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.showNotification = this.showNotification.bind(this);
    this.counter = this.counter.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    //console.log('udata: ',this.props.udata);
    let xdata = this.props.udata || {},
      bd = xdata.bd || {},
      xcode = bd.tla || "1M8wJEWIAcIEfILnCHpISD",
      xname = bd.nam;
    //console.log('using code ',xdata.bd);
    this.state = {
      count: 0,
      sbopen: false,
      sbmesg: "",
      navDrawerOpen: false,
      lwopen: false,
      seach: false,
      query: null,
      page: "dashboard",
      mode: "mode0",
      code: xcode, //'1M8wJEWIAcIEfILnCHpISD', //TODO: get from backend. this is for selified
      xdata: xdata,
      user: xname, //'Administrator' //TODO: Get from login/basicdata
      rqdata: {},
    };
  }

  componentDidMount() {
    let xdata = this.props.udata || {},
      bd = xdata.bd || {},
      xcode = bd.tla || "1M8wJEWIAcIEfILnCHpISD",
      xname = bd.nam;
    this.setState({
      code: xcode, //'1M8wJEWIAcIEfILnCHpISD', //TODO: get from backend. this is for selified
      xdata: xdata,
      user: xname,
    });
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.width !== nextProps.width) {
  //     this.setState({navDrawerOpen: nextProps.width === LARGE});
  //     console.log("master props width:",this.props.width,this.state.width,this.state.page);
  //   }
  // }

  cancelLogout() {
    this.setState({ lwopen: false });
  }

  handleLogout() {
    console.log("logging out");
    this.setState({ lwopen: true });
  }

  toggleDrawer() {
    this.setState({
      navDrawerOpen: !this.state.navDrawerOpen,
    });
  }

  openDrawer = () => {
    this.setState({ navDrawerOpen: true });
  };

  closeDrawer = () => {
    this.setState({ navDrawerOpen: false });
  };

  startSearch = () => {
    this.setState({ search: true });
  };

  stopSearch = () => {
    this.setState({ search: false });
  };

  handleSetContent(e: any, v: any, s?: boolean) {
    return new Promise((resolve, reject) => {
      if (v === "logout") {
        this.handleLogout();
        reject();
      } else {
        console.log("homepage e,v: ", e, v);
        this.setState({ page: v, query: e, search: s ? s : false });
        resolve(true);
      }
    });
  }

  counter() {
    this.setState({ count: (prevState: any) => prevState + 1 });
  }

  showNotification = (message: any, type: any, title: any) => {
    this.setState({ sbopen: true, sbmesg: message, sbtype: type });
    //this.setState({sbopen:true,sbmesg:message});
  };

  handleSBClose = () => this.setState({ sbopen: false, sbmesg: "" });

  render() {
    const {
      navDrawerOpen,
      xdata,
      user,
      code,
      search,
      page,
      query,
      lwopen,
      sbopen,
      sbmesg,
    } = this.state;
    const { classes } = this.props;
    const vertical = "top",
      horizontal = "center";

    return (
      // <ThemeProvider muiTheme={ThemeDefault}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <Header
            navDrawerOpen={navDrawerOpen}
            // hstyles={styles.header}
            search={search}
            page={page}
            menus={Data.menus}
            data={xdata}
            username={user}
            code={code}
            merchantCode={code}
            startSearch={this.startSearch}
            stopSearch={this.stopSearch}
            handleSetContent={this.handleSetContent.bind(this)}
            openDrawer={this.openDrawer.bind(this)}
            closeDrawer={this.closeDrawer.bind(this)}
            toggleDrawer={this.toggleDrawer.bind(this)}
          />

          <LeftDrawer
            navDrawerOpen={navDrawerOpen}
            search={search}
            page={page}
            menus={Data.menus}
            data={xdata}
            merchantCode={code}
            code={code}
            username={user}
            startSearch={this.startSearch}
            stopSearch={this.stopSearch}
            openDrawer={this.openDrawer.bind(this)}
            closeDrawer={this.closeDrawer.bind(this)}
            toggleDrawer={this.toggleDrawer.bind(this)}
            handleSetContent={this.handleSetContent.bind(this)}
          />

          <Content
            navDrawerOpen={navDrawerOpen}
            // cstyles={styles.content}
            className={classes.content}
            search={search}
            page={page}
            query={query}
            userData={xdata}
            merchantCode={code}
            code={code}
            //muin={MUINotification}
            //alertx={alertx}
            //counter={this.counter}
            startSearch={this.startSearch}
            stopSearch={this.stopSearch}
            handleSetContent={this.handleSetContent.bind(this)}
            showNotification={this.showNotification}
          />
          <div>
            <LogoutWizard
              open={lwopen}
              cancelLogout={this.cancelLogout.bind(this)}
              showNotification={this.showNotification.bind(this)}
            />
            {/* <MUINotification
              desktop={true}
              transitionName={{
                leave: 'dummy',
                leaveActive: 'fadeOut',
                appear: 'dummy',
                appearActive: 'zoomInUp'
              }}
              transitionAppear={true}
              transitionLeave={true}
            /> */}
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={sbopen}
              onClose={this.handleSBClose}
              autoHideDuration={6000}
              ContentProps={{
                "aria-describedby": "message-id",
              }}
              message={<span id="message-id">{sbmesg}</span>}
            />
          </div>
        </div>
      </div>
      // </ThemeProvider>
    );
  }
}

HomePage.propTypes = {
  children: PropTypes.element,
  width: PropTypes.number,
  udata: PropTypes.object,
};

//export default withWidth()(HomePage);

export default withStyles(styles, { withTheme: true })(HomePage);
