import React, { Component } from "react";
import PropTypes from "prop-types";
import { Theme } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import Drawer from "@mui/material/Drawer";
//import {spacing, typography} from '@mui/material/styles';
import { red } from "@mui/material/colors";
//import Menu from '@mui/material/Menu';
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
//import GridOff from '@mui/icons-material/GridOff';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DynamicMenu from "../menu/sidemenu/DynamicMenu";
//import ServiceList from './ServiceList';

//injectTapEventPlugin();

const drawerWidth = 240;
const miniDrawerWidth = 60;

const styles = (theme: Theme): any => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  rootPaper: {
    position: "relative",
  },
  drawerPaper: {
    [theme.breakpoints.down("sm")]: {
      width: miniDrawerWidth,
      overflowX: "hidden",
    },
    position: "relative",
    whiteSpace: "nowrap",
    height: "100%",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    maxHeight: "64px",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  logo: {
    cursor: "pointer",
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: "url(lib/img/logo.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "0 50%",
    backgroundColor: "transparent",
    paddingLeft: 40,
    paddingRight: 80,
    height: 56,
    width: drawerWidth - 80,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  avatarDiv: {
    padding: "15px 0 20px 10px",
    height: 45,
  },
  avatarIcon: {
    float: "left",
    display: "block",
    marginRight: 15,
    boxShadow: "0px 0px 0px 8px rgba(0,0,0,0.2)",
  },
  avatarSpan: {
    paddingTop: 12,
    display: "block",
    color: "white",
    fontWeight: 300,
    textShadow: "1px 1px #444",
  },
  adminMenu: {
    color: red,
  },
  userMenu: {
    fontWeight: theme.typography.fontWeightLight,
  },
});

class LeftDrawer extends Component<any, any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props: any) {
    super(props);
    this.state = {
      selectedItem: null,
    };
  }

  menuname = (menu: string) => {
    return menu.replace("/", "");
  };

  handleClick = (menu: any, page: any, index: any) => () => {
    console.log("handleclick:menu,page,index", menu, page, index);
    this.props.handleSetContent(null, page).then(() => {
      this.props.closeDrawer();
    });
  };

  render() {
    const { data, navDrawerOpen, closeDrawer, classes, theme } = this.props;
    //const eti = (data.bd||{}).eti;
    const unm = (data.bd || {}).unm;

    //console.log('data:',data);
    const avatarsrc = `/api/photo/${unm}`;
    const logosrc = `/api/logo/${data.bd.enm.replace(/\s/g, "") + ".png"}`;

    //console.log('servicelist',ServiceList());

    return (
      <Drawer
        variant="persistent"
        classes={{
          root: classNames(classes.rootPaper),
          //paper: classes.drawerPaper,
          paper: classNames(
            classes.drawerPaper,
            !navDrawerOpen && classes.drawerPaperClose
          ),
        }}
        open={navDrawerOpen}
        onClose={closeDrawer}
      >
        <div>
          <div className={classes.drawerHeader}>
            <div
              className={classes.logo}
              style={{ backgroundImage: `url(${logosrc})` }}
            />
            <IconButton onClick={closeDrawer}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>

          <Divider />
          <div className={classes.avatarDiv}>
            <Avatar src={avatarsrc} className={classes.avatarIcon}></Avatar>
            <span
              className={classNames(
                classes.avatarSpan,
                !navDrawerOpen ? classes.hide : undefined
              )}
            >
              {this.props.username}
            </span>
          </div>
          <Divider />
          <DynamicMenu data={data} handleClick={this.handleClick} />
          {/* <Divider />
        <StaticMenu data={data} menus={menus} value={this.state.selectedItem} onClick={this.handleClick} onChange={handleSetContent} /> */}
        </div>
      </Drawer>
    );
  }
}

LeftDrawer.propTypes = {
  navDrawerOpen: PropTypes.bool,
  menus: PropTypes.array,
  username: PropTypes.string,
  data: PropTypes.object,
  handleSetContent: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(LeftDrawer);
