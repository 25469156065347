import React, { Component, Fragment } from 'react';
import { Paper, type Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import {cyan, grey, pink, purple, orange, yellow, blue} from '@mui/material/colors';
import Grid from '@mui/material/Grid';

import Daily from './Daily';
import Recent from './Recent';
import Monthly from './Monthly';
//import Feature from './Feature';
import Distribution from './Distribution';
//import {bizpost} from '../../ajax';
import { ProgressDialog } from '../dialogs';

import moment from 'moment';
// import { ResponsiveContainer} from 'recharts';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
//import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

//import PageBase from '../site/PageBase';
//import LiveMap from './LiveMap';
import {apipost, bizpost} from '../../ajax';


const styles = (theme:Theme):any => ({
  root: {
    flexGrow: 1,
  },
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey['600'],
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color:'white'
  },
  paper: {
    padding: 30
  },
  clear: {
    clear: 'both'
  },

  maproot: {
    flexGrow: 1,
    flexShrink: 1,
  },
  mapboardpaper: {
    backgroundColor: 'white',
    //maxHeight: 600,
    height: '100%',
    // height: 150
    marginLeft: 10,
    marginRight: 10,
  },
  mapdiv: {
    // height: 95,
    //padding: '5px 15px 0 15px'
  },
  mapheader: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: 'white',
    backgroundColor: purple['600'],
    padding: 10,
  }
});

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

class Activity extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      dd: this.props.dd,
      ping: null,
      timer: 20000, //300000,
      count:0,
      loading: false,
      isFiltered: false, 

      mapping: null,
      maptimer: 60000, //300000,
      //mapcount:0,
      lastread: null,
      recordset: [],
      previousset:[],
      currentset:[]
    };
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  sessionError(error:any){
    const {type,name,title,data} = error||{};
    if(type==='error' && name==='fetcherror' && title === 'Unauthorised'){
      const {failure,code} = data || {};
      if(failure === true && code === 401){
        //session expired. reload page.
        //this.props.doRestart('Session Expired. reloading...');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }

  handleFilter = async (data:any) => {
    this.startLoading();
    const {start,end} = data;
    const baseparams = {s:'security',a:'dashfilter', sta:start, sto:end};
    const response = await bizpost(baseparams);
    this.stopLoading();
    this.setState({dd:response.dd});
    
  }

  getChannel(data:any){
    const {meta,customers:chandata} = data;
    const chans = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='customers');
    const rtn = chans.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = (chandata===null)?'':chandata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getMode(data:any){
    const {meta,mode:modedata={}} = data;
    const mdata = (modedata === null)?{}:modedata;
    const modes = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='mode');
    const rtn = modes.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = (modedata===null)?'':mdata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getInfo(data:any){
    //console.log('info.data',data);
    const {meta,input:source} = data;
    const infos = meta.filter((val:any,idx:number,arr:any[])=> val&&val.group==='info');
    const info = infos.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      //console.log('info.reduce.rec',rec,'source',source);
      rec.value = source[rec.name];
      //console.log('info.reduce.rec',rec,'source',source);
      acc.push(rec);
      return acc;
    },[]);
    return info;
  }

  getDaily(data:any){

    return Daily;
  }

  getMonthly(data:any){

    return  Monthly;
  }

  getRecent(data:any){

    return Recent;
  }

  getFeature(data:any){

    return null;
  }

  //////////////////////////////map//////////////////////////////
  // mapload = async(filename:string) => {
  //   try{
  //     const baseparams = {filename}; //,sta:date
  //     const response = await apipost('/api/resource/map','',baseparams);
  //     return response; //TODO: return file
  //   }
  //   catch(error){
  //     console.log('map.bizpost error',error);
  //     return Promise.reject(error);
  //   } 
  //   //TODO: call LoadMarkers
    
  // }

  // baseload = async() => {
  //   try{
  //     return await this.mapload('ghana-regions.json');
  //   }
  //   catch(error){
  //     console.log('baseload error',error);
  //     return Promise.reject(error);
  //   } 
  // }

  // placesload = async() => {
  //   try{
  //     return await this.mapload('ghana-towns.json');
  //   }
  //   catch(error){
  //     console.log('placesload error',error);
  //     return Promise.reject(error);
  //   } 
  // }

  merge = (p:string, ...arrs:any[]) => [].concat(...arrs).reduce((a, b) => !a.filter(c => b[p] === c[p]).length ? [...a, b] : a, []);


  // liveLoad = async(code:any,date:any) => {
  //   try{
  //     const {recordset:previousset,lastread} = this.state;
  //     const {eni} = this.props;
  //     const baseparams = {s:'marker',a:'find',eni,stp:lastread}; 
  //     const response = await bizpost(baseparams);
  //     const {sd:currentset} = response;
  //     const recordset = this.merge('rid',previousset,currentset);
  //     this.setState({recordset,previousset,currentset});
  //   }
  //   catch(error){
  //     console.log('map.bizpost error',error);
  //   } 
    
  // }

  ttFormatTime = (stamp:any) => {
    return moment.unix(stamp).format('DD MMM');
  }

  render() {
    const {theme,classes} = this.props;
    const { loading} = this.state;
    const {modes,chans} = this.props;
    const {daily,monthly,recent} = this.props;

    return (
      <Fragment>
        <Grid  container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12} sm={6}>
            <Recent data={recent||[]} title='Recent Activity' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Daily data={daily||[]} title='Daily Activity' handleFilter={this.handleFilter} icons/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Monthly data={monthly||[]} title='Monthly Activity' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={modes||[]} title='Activity By Segment' color='orange' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={chans||[]} title='Activity Distribution' color='blue' icons/>
          </Grid>
        </Grid>

        <ProgressDialog  
          open={loading} 
          loading={loading}
          startLoading={this.startLoading}
          stopLoading={this.stopLoading}
        />

      </Fragment>
    );
  }
};

export default withStyles(styles, { withTheme: true })(Activity);
