
const basicconfig = {
  
  agency: {
    service:{
      name:'agency',
      title:'Agency',
    }
  },

  country: {
    service:{
      name:'country',
      title:'Country',
    }
  },

  currency: {
    service:{
      name:'currency',
      title:'Currency',
    }
  },

  dashtype: {
    service:{
      name:'dashtype',
      title:'Dash Type',
    }
  },

  devicetype: {
    service:{
      name:'devicetype',
      title:'Device Type',
    }
  },

  gender: {
    service:{
      name:'gender',
      title:'Gender',
    }
  },

  idtype: {
    service:{
      name:'idtype',
      title:'ID Type',
    }
  },

  meterstatus: {
    service:{
      name:'meterstatus',
      title:'Meter Status',
    }
  },

  orderstatus: {
    service:{
      name:'orderstatus',
      title:'Order Status',
    }
  },

  paramgroup: {
    service:{
      name:'paramgroup',
      title:'Parameter Group',
    }
  },

  paramstatus: {
    service:{
      name:'paramstatus',
      title:'Parameter Status',
    }
  },

  productionstatus: {
    service:{
      name:'productionstatus',
      title:'Production Status',
    }
  },
	 //
  region: {
    service:{
      name:'region',
      title:'Region',
    }
  },

  report: {
    service:{
      name:'report',
      title:'Reports',
    }
  },

  station: {
    service:{
      name:'station',
      title:'Station',
    }
  },

  //////////////////////////

  uidevice: {
    service:{
      name:'uidevice',
      title:'UI Device',
    }
  },

  uidoc: {
    service:{
      name:'uidoc',
      title:'UI Document',
    }
  },

  uilang: {
    service:{
      name:'uilang',
      title:'UI Language',
    }
  },

  uipage: {
    service:{
      name:'uipage',
      title:'UI Page',
    }
  },

  uisection: {
    service:{
      name:'uisection',
      title:'UI Section',
    }
  },

  version: {
    service:{
      name:'version',
      title:'App Version',
    }
  },
};

export {basicconfig};
